<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title title="Audits" subtitle="Browse and Manage your Audits!" :badge="this.total" />
    <v-row class="mb-2 justify-space-between">
      <v-col></v-col>
      <v-col cols="12" sm="12" md="9" lg="8" class="py-0">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            storageKey="audits"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 audit-table table-sticky-header-exclude-footer"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.UserFullName`]="{ item }">
        <v-row>
          <v-col
            class="d-flex justify-content-center"
            align-content-center
            justify-center
            style="align-items: center"
          >
            <h3>{{ item.userFullName }}</h3>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.Url`]="{ item }">
        <v-row>
          <v-col
            class="d-flex justify-content-center"
            align-content-center
            justify-center
            style="align-items: center"
          >
            <kbd>{{ item.url }}</kbd>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.Date`]="{ item }">
        <v-row>
          <v-col
            class="d-flex justify-content-center"
            align-content-center
            justify-center
            style="align-items: center"
          >
            <dater :date="item.date" date-only></dater>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.ActionType`]="{ item }">
        <v-row>
          <v-col
            class="d-flex justify-content-center"
            align-content-center
            justify-center
            style="align-items: center"
          >
            {{ item.actionType }}
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.IpAddress`]="{ item }">
        <v-row>
          <v-col
            class="d-flex justify-content-center"
            align-content-center
            justify-center
            style="align-items: center"
          >
            <code>{{ item.ipAddress }}</code>
          </v-col>
        </v-row>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <audit-details ref="auditDetails"></audit-details>
  </v-container>
</template>

<script>
import ApiService from "../services/audit-service.js";
import AuditDetails from "../components/AuditDetails.vue";
import auditHeader from "../config/tables/audit.header";

export default {
  data() {
    return {
      storageKey: "Audits",
      entities: [],
      selected: {},
      total: 0,
      search: "",
      valid: false,
      clientsTimerId: null,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["Date"],
        sortDesc: [true],
      },
      loadingStates: {
        table: false,
      },
      headers: auditHeader,
      defaultHeaders: [],
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.$refs.mainSearch.focus();
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    rowClicked(row) {
      this.$log(this.$refs.auditDetails);
      this.$refs.auditDetails.open(row);
    },
    getData() {
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { AuditDetails },
};
</script>

<style lang="scss">
.audit-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.img {
  border-radius: 5px;
  margin: 5px;
}
</style>
